<template>
  <div id="vm-cola-order" :class="{ transparent: $route.name.indexOf('Payment') > -1 }">
    <div v-if="['vmColaOrder'].includes(routeName)" class="vm-cola-order__user">
      <div class="vm-cola-order__user--left">
        <figure v-if="user && user.image">
          <img :src="user.image" :alt="user.name" />
        </figure>
        <h4 v-if="user && user.name" class="name">{{ user.name }}!</h4>
        <h4 v-else>Guest</h4>
      </div>
      <template v-if="['vendingMachineLogin', 'vmColaOrder'].includes(routeName)">
        <div class="vm-cola-order__user--logout" @click="openModalCancelShopping">
          <span>Keluar</span>
        </div>
      </template>
      <template v-else>
        <div class="vm-cola-order__user--logout" @click="logout">
          <span>Keluar</span>
        </div>
      </template>
    </div>
    <component
      :is="order_template"
      name="order"
      :cancel-shopping="modal_cancel_shopping"
      @close-shopping-modal="modal_cancel_shopping = false"
      @logout="logout"
    />

    <!-- <ModalCancelShopping
      v-if="modal_cancel_shopping"
      @close="modal_cancel_shopping = false"
      @logout="logout"
    /> -->
  </div>
</template>

<script>
import helpers from '@/mixins/helpers';
import ls from '@/components/helpers/local-storage';

export default {
  name: 'vmColaOrder',
  components: {
    ModalCancelShopping: () => import('@/components/vm-cola/snippets/modal/modal-cancel-shopping.vue'),
  },
  mixins: [helpers],
  beforeRouteLeave(to, from, next) {
    if (from.name == 'vmColaPaymentSuccess') {
      ls.remove('PRODUCT_LIST');
      ls.remove('SLOT_PRODUCT');
    }
    if (to.name == 'vmColaHome' || from.name.indexOf('Payment') > -1) {
      this.cancelOrder();
    }
    next();
  },

  data() {
    return {
      modal_cancel_shopping: false,
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    order_template() {
      if (this.$route.name == 'vmColaOrder') {
        return () => import('./shopping-bag/shopping-bag');
      }
      if (['vmColaLogin', 'vendingMachineLogin'].includes(this.$route.name)) {
        return () => import('./login/login');
      }
      if (this.$route.name == 'vmColaPayment') {
        return () => import('./payment/payment');
      }
      if (this.$route.name == 'vmColaPaymentSuccess') {
        return () => import('./payment/payment-success');
      }
      if (this.$route.name == 'vmColaPaymentSuccessFailed') {
        return () => import('./payment/payment-success-failed');
      }
      if (this.$route.name == 'vmColaPaymentFailed') {
        return () => import('./payment/payment-failed');
      }
    },
    routeName() {
      return this.$route.name;
    },
    login_status() {
      return this.$store.state.QRcode.login_status;
    },
    user() {
      return this.$store.state.QRcode.user;
    },
    orderCancelledLoading() {
      return this.$store.state.vmCola.order_cancelled_loading;
    },
  },

  methods: {
    openModalCancelShopping() {
      if (this.orderCancelledLoading) {
        return;
      }

      this.modal_cancel_shopping = true;
    },
    async logout() {
      const is_guest = await ls.get('is_guest');
      if (is_guest) {
        await this.removeProducts();
      }

      this.$store.dispatch('userLogout', 'vmColaHome');
    },
  },
};
</script>
